import React, { useEffect, useState } from "react";
import Case from "./Case";
import { Link } from "react-router-dom";
import { services } from "../utils/environments/api";

const Service1 = () => {
  const [service, setService] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    serviceData();
  }, []);

  const serviceData = async () => {
    try {
      const res = await services.get("get_service_typ_api.php");
      if (res.data.data) {
        setService(res.data.data);
      } else {
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    serviceContent();
  }, []);
  const serviceContent = async () => {
    try {
      const res = await services.get("get_service_typ_api.php");
      if (res.data.data) {
        setContent(res.data.data);
      } else {
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  return (
    <div>
      <Case />

      {/* SERVICE TWO */}
      <div class="services-section-two margin-top">
        <div class="auto-container">
          <div class="upper-box">
            <div class="icon-one"></div>
            <div class="icon-two"></div>
            <div class="icon-three"></div>
            {/* <!-- Sec Title --> */}
            <div class="sec-title light centered">
              <div class="title">WHO WE ARE</div>
              <h2>
                We deal with the aspects of <br />
                professional IT Services
              </h2>
            </div>
          </div>
          <div class="inner-container">
            <div class="row clearfix">
              {/* <!-- Service Block Two --> */}

              {service.map((item) => (
                <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
                  <Link to={`/Service?id=${item.servicetype_id}`}>
                    <div class="inner-box" key={item.servicetype_id}>
                      <div class="shape-one"></div>
                      <div class="shape-two"></div>
                      <h5>{item.service}</h5>
                      <div class="text">
                        <p class="text">{item.content}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Service1;

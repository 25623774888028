import React from "react";
import About from "./About";
import { Link } from "react-router-dom";
const AboutUs = () => {
  return (
    <div>
      <div class="page-title">
        <div
          class="pattern-layer-one"
          // style={{
          //   backgroundImage:
          //     "url(/../../assets/Images/background/pattern-16.png)",
          // }}
        ></div>
        <div class="auto-container">
          <h2>About us</h2>
          <ul class="page-breadcrumb">
            <li>
              <Link to="/">home &gt;</Link>
            </li>
            <li>About us</li>
          </ul>
        </div>
      </div>
      <div class="about-section">
        <div class="auto-container">
          {/* <!-- Sec Title --> */}
          <div class="sec-title">
            <div class="title">ABOUT COMPANY</div>
            <h2>
              Empowering Innovation in <br />
              the Digital Era.
            </h2>
          </div>
          <div class="row clearfix">
            {/* <!-- Content Column --> */}
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="text">
                  At Vincosys, we are passionate about transforming innovative
                  ideas into powerful digital solutions that drive growth and
                  success. As a dynamic technology company, we specialize in
                  creating customized digital solutions that elevate businesses
                  in today’s fast-paced tech landscape. With a diverse team of
                  experts, we bring together deep industry knowledge and the
                  latest advancements in technology to deliver robust solutions
                  tailored to meet our clients' unique needs.
                  {/* <ul>
                    <li>Commitment to Quality</li>
                    <li>Future-Focused Solutions</li>
                  </ul> */}
                </div>
                <div className="text">
                  Our expertise spans a wide range of services, from Internet of
                  Things (IoT) solutions to mobile app development and web-based
                  applications. We understand the complexities of modern
                  business environments and provide comprehensive services that
                  streamline processes, enhance user experiences, and unlock new
                  growth opportunities.
                </div>
                <div class="blocks-outer">
                  {/* <!-- Feature Block --> */}
                  <div class="feature-block">
                    <div class="inner-box">
                      <div class="icon">
                        <i class="ri-award-fill"></i>
                      </div>
                      <h6>Experience</h6>
                      <div class="feature-text">
                        Our great team of more than 30 software experts.
                      </div>
                    </div>
                  </div>

                  {/* <!-- Feature Block --> */}
                  <div class="feature-block">
                    <div class="inner-box">
                      <div class="icon">
                        <i class="ri-settings-2-line"></i>
                      </div>
                      <h6>Solutions</h6>
                      <div class="feature-text">Future-Focused Solutions</div>
                    </div>
                  </div>
                </div>

                {/* <Link to="/AboutUs" class="lightbox-image theme-btn btn-style-one"><span class="txt">Read More</span></Link> */}
              </div>
            </div>

            {/* <!-- Images Column --> */}
            <div class="images-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="main">
                  <div class="pattern-layer"></div>
                </div>
                <div class="images-outer parallax-scene-1">
                  <div class="image" data-depth="0.10">
                    <img
                      src="../../assets/Images/resource/about-1.jpg"
                      alt=""
                    />
                  </div>
                  <div class="image-two" data-depth="0.30">
                    <img
                      src="../../assets/Images/resource/about-2.jpg"
                      alt=""
                    />
                  </div>
                  <div class="image-three" data-depth="0.20">
                    <img
                      src="../../assets/Images/resource/about-3.jpg"
                      alt=""
                    />
                  </div>
                  <div class="image-four" data-depth="0.30">
                    <img
                      src="I../../assets/Images/resource/about-4.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {/* <Link to="/Service1" class="learn">
                <span
                //   class="arrow flaticon-long-arrow-pointing-to-the-right"
                >
                  <i class="ri-arrow-right-line"></i>
                </span>
                Learn More About Company
              </Link> */}
            </div>
            <div class="content-column col-lg-12 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="text">
                  At Vincosys, we pride ourselves on our commitment to
                  excellence, quality, and client satisfaction. We don’t just
                  deliver technology – we deliver solutions that enable
                  businesses to stay competitive and thrive. By partnering with
                  us, our clients gain access to leading-edge technology
                  solutions designed to help them adapt and succeed in an
                  ever-evolving digital world. Whether it’s building a secure
                  IoT network, developing a custom mobile application, or
                  creating a responsive web platform, we are dedicated to
                  turning vision into reality and empowering businesses to
                  achieve their goals. Let Vincosys be your partner in
                  navigating the digital age, with customized strategies and
                  cutting-edge technology that propel your business toward
                  sustainable growth and success. Choosing Vincosys as your
                  technology partner means having a dedicated collaborator
                  focused on your long-term success. We emphasize flexibility,
                  innovation, and growth in every solution we deliver, ensuring
                  our technologies adapt to your industry's changing needs. Our
                  goal is to empower businesses to not only thrive but to lead,
                  creating lasting impact in a digital-first world.
                </div>

                {/* <Link to="/AboutUs" class="lightbox-image theme-btn btn-style-one"><span class="txt">Read More</span></Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
